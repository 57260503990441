import React, { useEffect, useState } from "react";
import { Row, Col, Button, Input, Skeleton } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import shortLinkGIF from "../../FormLocalSearch/assets/audit/shortLink.gif";

import ratingPicture from "../../FormLocalSearch/assets/audit/Note.png";
import reviewsPicture from "../../FormLocalSearch/assets/audit/nombre-avis.png";
import ProgressCircle from "../ProgressCircle";

const Reviews = ({ auditInfo }) => {
  const [score, setScore] = useState();
  const [shortLink, setShortLink] = useState();
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getScore = async () => {
      let score = 0;
      if (auditInfo.Nbreviews > 10) {
        score = score + 50;
      }
      if (auditInfo.Rating >= 4) {
        score = score + 50;
      }

      setScore(score);
      setShortLink(
        `http://search.google.com/local/writereview?placeid=${auditInfo.PlaceID}&hl=fr`
      );
      setLoading(false);
    };
    getScore();
  }, [auditInfo.Nbreviews, auditInfo.Rating, auditInfo.BusinessID]);
  if (loading) {
    return (
      <>
        <div className="featureblock-container">
          <div className="card-header">
            <span>Indispensables</span>
          </div>
          <div className="card-graph-body">
            <div className="sumup-category">
              <Skeleton active />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="featureblock-container">
          <div className="card-header">
            <span>Avis</span>
          </div>
          <div className="card-graph-body">
            <div className="sumup-category">
              <Row>
                <Col xl={10} lg={10} sm={24} className="progress-category">
                  <ProgressCircle type="circle" score={score} />
                </Col>
                <Col xl={14} lg={14} sm={24}>
                  <div style={{ marginTop: "20px" }}>
                    Les avis sont le nerf de la guerre en SEO local et pour la
                    réputation de votre entreprise. Il a été démontré que les
                    établissements qui génèrent le plus de chiffre d’affaires
                    ont généralement le plus d’avis.
                  </div>
                </Col>
              </Row>
            </div>
            <div className="criteria-audit">
              <Row gutter={30}>
                <Col xl={14} lg={14} sm={24}>
                  <div className="criteria-status">
                    <span>
                      {auditInfo.Nbreviews > 10 ? (
                        <>
                          <span
                            role="img"
                            className="issue-icon"
                            aria-label="emoji"
                          >
                            ✅
                          </span>
                          Nombre d'avis minimum correct{" "}
                        </>
                      ) : (
                        <>
                          <span
                            role="img"
                            className="issue-icon"
                            aria-label="emoji"
                          >
                            ❌
                          </span>
                          Nombre d'avis minimum insuffisant
                        </>
                      )}
                    </span>
                  </div>
                  <div className="criteria-description">
                    <span>
                      Vous devez améliorer votre e-réputation et votre seo local
                      grâce aux avis.
                      <br />
                      <br />
                      Avoir des avis vous permet d’avoir plus de visibilité sur
                      Google. Les gens prennent de plus en plus une décision
                      d’achat grâce aux avis.
                      <br />
                      Des études montrent qu’avoir plus d’avis sur Google My
                      Business peut{" "}
                      <b>augmenter vos conversions de plus de 20%</b>.
                    </span>
                  </div>
                </Col>
                <Col xl={10} lg={10} sm={24}>
                  {" "}
                  <img src={reviewsPicture} width="80%" alt="reviews" />
                </Col>
              </Row>
            </div>
            <div className="criteria-audit">
              <Row gutter={30}>
                <Col xl={14} lg={14} sm={24}>
                  <div className="criteria-status">
                    <span>
                      {auditInfo.Rating >= 4 ? (
                        <>
                          {" "}
                          <span
                            role="img"
                            className="issue-icon"
                            aria-label="emoji"
                          >
                            ✅
                          </span>
                          Note moyenne bonne{" "}
                        </>
                      ) : (
                        <>
                          {" "}
                          <span
                            role="img"
                            className="issue-icon"
                            aria-label="emoji"
                          >
                            ❌
                          </span>
                          Note moyenne à améliorer
                        </>
                      )}
                    </span>
                  </div>
                  <div className="criteria-description">
                    <span style={{ fontSize: 16 }}>
                      Votre note moyenne est de <b>{auditInfo.Rating}</b>
                    </span>
                    <br />
                    <br />
                    <span>
                      <b>Toujours avoir une note supérieure à 4/5.</b>
                      <br />
                      Il a été démontré qu'avoir une bonne note générale
                      améliorer votre taux de clics.
                      <br />
                      Un établissement avec 5 étoiles recevra plus de clics
                      qu’un établissement avec 2 étoiles.
                    </span>
                  </div>
                </Col>
                <Col xl={10} lg={10} sm={24}>
                  {" "}
                  <img src={ratingPicture} width="80%" alt="rating" />
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="featureblock-container-info">
          <div className="card-header">
            <span>Le saviez-vous ?</span>
          </div>
          <div className="card-graph-body">
            <div className="sumup-category">
              <Row>
                <Col xl={13} lg={13} sm={24}>
                  <div className="criteria-status">
                    <span>
                      <span
                        role="img"
                        className="issue-icon"
                        aria-label="emoji"
                      >
                        💡
                      </span>
                      Vous pouvez obtenir simplement plus d'avis sur Google.{" "}
                    </span>
                  </div>
                  <div className="criteria-description">
                    <span style={{ fontSize: 16 }}>
                      Connaissez-vous le nom de lien court ?
                    </span>
                    <br />
                    <span>
                      <br />
                      L’objectif de ce lien est d’amener vos clients directement
                      à la section avis de votre fiche Google.
                      <br />
                      <br />
                      Cela facilite le parcours pour votre client satisfait et
                      améliore vos chances d’avoir un avis.
                      <br />
                      <br />
                      <b>
                        Copier votre nom de lien court ci-dessous et partagez-le
                        à vos clients !
                      </b>
                      <div className="short-link">
                        <Input value={shortLink} disabled />
                        <CopyToClipboard
                          text={shortLink}
                          onCopy={() => setCopied(true)}
                        >
                          <Button
                            type="primary"
                            style={{
                              backgroundColor: "#2e4556",
                              borderColor: "#2e4556",
                              width: "fit-content",
                              height: "40px",
                              borderRadius: "0px 5px 5px 0px",
                            }}
                          >
                            {!copied ? "Copier" : "Copié !"}
                          </Button>
                        </CopyToClipboard>
                      </div>
                    </span>
                  </div>
                </Col>
                <Col xl={11} lg={11} sm={24}>
                  <img src={shortLinkGIF} width="100%" alt="gif shortlink" />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Reviews;
