import React, { useState } from "react";
import { message, Button, Input, Form, Row, Col, InputNumber } from "antd";
import SecureIcon from "../../components/FormLocalSearch/assets/secure.svg";
import CBIcon from "../../components/FormLocalSearch/assets/cb.svg";

const FormMoreInfo = Form.create({ name: "more-info" })((props) => {
  const { submitBusiness, businessAddress } = props;

  const [loading, setLoading] = useState(false);
  const form = props.form;

  const onSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        try {
          let data = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            company: values.company,
            nbBusinesses: values.nbBusinesses,
          };
          if (values.email.includes("leroymerlin")) {
            message.warning(
              "Vous n'avez plus de crédit. Contactez-nous pour obtenir un audit."
            );
          } else {
            await submitBusiness(businessAddress, data);
          }
        } catch (error) {
          console.log(error);
          message.error("Erreur lors de la sauvergarde");
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const { getFieldDecorator } = props.form;

  return (
    <div className="container-billing">
      <Form layout="vertical" className="form-settings">
        <Row gutter={[20]}>
          <Col xl={12} ml={12} sm={12} xs={12}>
            <Form.Item label="Prénom">
              {getFieldDecorator("firstName", {
                rules: [
                  {
                    required: true,
                    message: "Veuillez entrer votre prénom",
                  },
                ],
              })(<Input className="settings-input" placeholder="Tim" />)}
            </Form.Item>
          </Col>
          <Col xl={12} ml={12} sm={12} xs={12}>
            <Form.Item label="Nom">
              {getFieldDecorator("lastName", {
                rules: [
                  {
                    required: true,
                    message: "Veuillez entrer votre nom",
                  },
                ],
              })(<Input className="settings-input" placeholder="Cook" />)}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Email">
          {getFieldDecorator("email", {
            rules: [
              { required: true, message: "Veuillez indiquer votre email" },
              {
                type: "email",
                message: "email non valide",
              },
            ],
          })(<Input className="settings-input" placeholder="tim@apple.com" />)}
        </Form.Item>
        <Form.Item label="Entreprise">
          {getFieldDecorator("company", {
            rules: [
              { required: true, message: "Veuillez indiquer votre entreprise" },
            ],
          })(<Input className="settings-input" placeholder="Apple Inc." />)}
        </Form.Item>
        <Form.Item label="Nombre d'établissements">
          {getFieldDecorator("nbBusinesses", {
            rules: [
              {
                required: true,
                message: "Indiquez le nombre d'établissements gérés.",
              },
              {
                type: "number",
                message: "Indiquez une nombre valide",
              },
            ],
          })(
            <InputNumber
              min={1}
              max={100000}
              className="settings-input"
              placeholder="400"
            />
          )}
        </Form.Item>
      </Form>
      <br />
      <Row gutter={[20]}>
        <Col xl={8} ml={8} sm={24} xs={24}>
          <Button
            className="submit-form"
            htmlType="submit"
            loading={loading}
            onClick={onSubmit}
          >
            Analyser
          </Button>
        </Col>
        <Col xl={16} ml={16} sm={24} xs={24} className="logo-form">
          <div>
            <img src={CBIcon} width="50px" /> <span>Aucune CB requise</span>
          </div>
          <div>
            <img src={SecureIcon} width="50px" /> <span>Site sécurisé</span>
          </div>
        </Col>
      </Row>
    </div>
  );
});

export default Form.create()(FormMoreInfo);
