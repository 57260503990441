import React from "react";
import { Link } from "react-router-dom";
import Hero from "../../components/Hero/Hero";
import { Card } from "antd";
import "./home.css";
import IconGoogle from "../../components/FormLocalSearch/assets/icon-google.png";
import IconMaps from "../../components/FormLocalSearch/assets/icon-maps.png";
import { Helmet } from "react-helmet";
const API_URL = process.env.REACT_APP_API_URL || "http://localhost:4000/tech/";

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>Répertoire d'outils SEO local | LocalRanker</title>
        <meta
          name="description"
          content="Vous voulez améliorer votre SEO local ? Aidez-vous de nos outils gratuits de référencement local !"
        />
      </Helmet>
      <Hero
        title="Répertoire d'outils SEO local"
        subtitle="Découvrez nos outils SEO local gratuits et améliorez votre référencement."
        emoji=" 🛠"
      />
      <div className="tools">
        <div className="cards-tools">
          <Link to="/serp-local-google">
            <Card bordered={true}>
              <div className="head">
                {" "}
                <div className="card-title">
                  <img src={IconMaps} width="20px" alt="icon google" />
                  {"    "}
                  Résultats Google hyper localisé
                </div>
                <div className="tag-container">
                  <div className="tag">🔥 Nouveau</div>
                </div>
              </div>

              <div className="card-description">
                Découvrez les résultats de recherche locales sur la requête de
                votre choix et comparez votre référencement local face à la
                concurrence.
              </div>
              <div className="test-tool">Tester l'outil maintenant</div>
            </Card>
          </Link>
          <Link to="/audit-google-my-business">
            <Card bordered={true}>
              <div className="head">
                {" "}
                <div className="card-title">
                  <img src={IconGoogle} width="20px" alt="icon google" />
                  {"    "}
                  Audit fiche Google My Business
                </div>
                <div className="tag-container">
                  {/* <div className="tag">🔥 Nouveau</div> */}
                </div>
              </div>

              <div className="card-description">
                Réalisez un audit de votre fiche GMB en 1 clic et commencez à
                optimiser votre fiche pour dépassez vos concurrents locaux.
              </div>
              <div className="test-tool">Tester l'outil maintenant</div>
            </Card>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
