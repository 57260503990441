import React from "react";
import { Modal } from "antd";
import FormMoreInfoSerp from "./FormMoreInfoSerp";

const ModalMoreInfoSerp = ({
  modalBusinessInfo,
  setModalBusinessInfo,
  onLocalSearchSubmit,
  location,
}) => {
  return (
    <div>
      <Modal
        title="Dites nous en plus sur vous 😇"
        visible={modalBusinessInfo}
        onCancel={() => {
          setModalBusinessInfo(false);
        }}
        footer={null}
      >
        <FormMoreInfoSerp
          onLocalSearchSubmit={onLocalSearchSubmit}
          location={location}
        />
      </Modal>
    </div>
  );
};

export default ModalMoreInfoSerp;
