import React, { useEffect, useState } from "react";
import { Row, Col, Skeleton } from "antd";

import addressPicture from "../../FormLocalSearch/assets/audit/adresse.png";
import siteGood from "../../FormLocalSearch/assets/audit/site-web-good.jpg";
import siteWrong from "../../FormLocalSearch/assets/audit/site-web-wrong.jpg";
import horairesPicture from "../../FormLocalSearch/assets/audit/Horaires.png";
import phonePicture from "../../FormLocalSearch/assets/audit/telephone.png";
import descriptionPicture from "../../FormLocalSearch/assets/audit/description.png";
import ProgressCircle from "../ProgressCircle";

const Informations = ({ auditInfo }) => {
  const [score, setScore] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getScore = async () => {
      let score = 0;
      if (auditInfo.Address) {
        score = score + 20;
      }
      if (auditInfo.Phone) {
        score = score + 20;
      }
      if (auditInfo.Website) {
        score = score + 20;
      }
      if (auditInfo.IsBusinessHoursFilled) {
        score = score + 20;
      }
      if (auditInfo.MerchandDescription) {
        score = score + 20;
      }
      setScore(score);
      setLoading(false);
    };
    getScore();
  }, [
    auditInfo.Address,
    auditInfo.Phone,
    auditInfo.Website,
    auditInfo.IsBusinessHoursFilled,
    auditInfo.MerchandDescription,
  ]);

  if (loading) {
    return (
      <>
        <div className="featureblock-container">
          <div className="card-header">
            <span>Indispensables</span>
          </div>
          <div className="card-graph-body">
            <div className="sumup-category">
              <Skeleton active />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="featureblock-container">
        <div className="card-header">
          <span>Informations</span>
        </div>
        <div className="card-graph-body">
          <div className="sumup-category">
            <Row>
              <Col xl={10} lg={10} sm={24} className="progress-category">
                <ProgressCircle type="circle" score={score} />
              </Col>
              <Col xl={14} lg={14} sm={24}>
                <div style={{ marginTop: "20px" }}>
                  Vous devez remplir au maximum les informations sur votre fiche
                  google my business afin d’améliorer votre visibilité. Vos
                  coordonnées doivent être correctes et définitives.
                </div>
              </Col>
            </Row>
          </div>
          <div className="criteria-audit">
            <Row gutter={30}>
              <Col xl={14} lg={14} sm={24}>
                <div className="criteria-status">
                  <span>
                    {auditInfo.Address ? (
                      <>
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ✅
                        </span>
                        Adresse renseignée{" "}
                      </>
                    ) : (
                      <>
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ❌
                        </span>
                        Adresse non-renseignée
                      </>
                    )}
                  </span>
                </div>
                <div className="criteria-description">
                  <span>
                    Mettre une adresse si vous avez un établissement physique.
                    Google favorise les fiches avec adresse car cela montre que
                    vous êtes un vrai établissement et non un spam.
                  </span>
                </div>
              </Col>
              <Col xl={10} lg={10} sm={24}>
                <img src={addressPicture} width="80%" alt="adresse" />
              </Col>
            </Row>
          </div>
          <div className="criteria-audit">
            <Row gutter={30}>
              <Col xl={14} lg={14} sm={24}>
                <div className="criteria-status">
                  <span>
                    {auditInfo.IsWebsiteFilled ? (
                      <>
                        {" "}
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ✅
                        </span>
                        Site web renseigné{" "}
                      </>
                    ) : (
                      <>
                        {" "}
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ❌
                        </span>
                        Site web non-renseigné
                      </>
                    )}
                  </span>
                </div>
                <div className="criteria-description">
                  {auditInfo.Website ? (
                    <>
                      <span style={{ fontSize: 16 }}>
                        <b>Votre site web renseigné : {auditInfo.Website}</b>
                      </span>
                      <br />
                      <br />
                    </>
                  ) : (
                    ""
                  )}
                  <span>
                    Vous devez mettre la bonne adresse de votre site web. Ne pas
                    utiliser votre page facebook ou linkedin dans cette section.
                  </span>
                </div>
              </Col>
              <Col xl={10} lg={10} sm={24}>
                {auditInfo.Website ? (
                  <img src={siteGood} width="80%" alt="site web good" />
                ) : (
                  <img src={siteWrong} width="80%" alt="site web bad" />
                )}{" "}
              </Col>
            </Row>
          </div>
          <div className="criteria-audit">
            <Row gutter={30}>
              <Col xl={14} lg={14} sm={24}>
                <div className="criteria-status">
                  <span>
                    {auditInfo.IsBusinessHoursFilled ? (
                      <>
                        {" "}
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ✅
                        </span>
                        Horaires renseignés{" "}
                      </>
                    ) : (
                      <>
                        {" "}
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ❌
                        </span>
                        Horaires non-renseignés
                      </>
                    )}
                  </span>
                </div>
                <div className="criteria-description">
                  <span>
                    Vos horaires d’ouvertures et fermetures doivent être visible
                    pour informer vos clients de vos disponibilités.
                    <br />
                    <br />
                    Ne vous trompez pas d’heure cela pourrait frustrer certains
                    visiteurs.
                  </span>
                </div>
              </Col>
              <Col xl={10} lg={10} sm={24}>
                <img src={horairesPicture} width="80%" alt="horaires" />
              </Col>
            </Row>
          </div>
          <div className="criteria-audit">
            <Row gutter={30}>
              <Col xl={14} lg={14} sm={24}>
                <div className="criteria-status">
                  <span>
                    {auditInfo.Phone ? (
                      <>
                        {" "}
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ✅
                        </span>
                        Téléphone renseignés{" "}
                      </>
                    ) : (
                      <>
                        {" "}
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ❌
                        </span>
                        Téléphone non-renseigné
                      </>
                    )}
                  </span>
                </div>
                <div className="criteria-description">
                  {auditInfo.Phone ? (
                    <>
                      <span style={{ fontSize: 16 }}>
                        <b>Votre téléphone renseigné : {auditInfo.Phone}</b>
                      </span>
                      <br />
                      <br />
                    </>
                  ) : (
                    ""
                  )}
                  <span>
                    Mettez votre numéro de téléphone principal et évitez qu’il
                    soit changé.
                  </span>
                </div>
              </Col>
              <Col xl={10} lg={10} sm={24}>
                <img src={phonePicture} width="80%" alt="phone" />
              </Col>
            </Row>
          </div>
          <div className="criteria-audit">
            <Row gutter={30}>
              <Col xl={14} lg={14} sm={24}>
                <div className="criteria-status">
                  <span>
                    {auditInfo.MerchandDescription ? (
                      <>
                        {" "}
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ✅
                        </span>
                        Description renseignée{" "}
                      </>
                    ) : (
                      <>
                        {" "}
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ❌
                        </span>
                        Description non-renseignée
                      </>
                    )}
                  </span>
                </div>
                <div className="criteria-description">
                  <span>
                    Ecrivez une petite description pour votre entreprise avec
                    votre mot clé principal et votre zone géographique.
                    N’hésitez pas à mettre en avant ce qui vous distingue des
                    autres concurrents.
                    <br />
                    <br />
                    Ne pas sur-optimiser votre texte, c’est à dire ne pas
                    répéter plusieurs fois vos mots clés.
                  </span>
                </div>
              </Col>
              <Col xl={10} lg={10} sm={24}>
                <img
                  src={descriptionPicture}
                  width="80%"
                  alt="description renseignée"
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
};
export default Informations;
