import React from "react";

import { Button, Icon } from "antd";

import "./GMBScoring.css";

const LockOverview = ({ isIssue, handleNewUnlockStripe, issuesRemaning }) => {
  return (
    <div className="lock-overview-container">
      <Button
        onClick={() => {
          window.open(
            "https://localranker.fr/demander-demo/?utm_source=localranker.tech&utm_campaign=banner-bottom",
            "_blank"
          );
        }}
        type="secondary"
        className="locked-btn"
      >
        <Icon type="unlock" />
        Débloquez l'audit complet sur LocalRanker
      </Button>
      {isIssue ? (
        <>
          <br />
          <div style={{ textAlign: "center" }}>
            <p>({issuesRemaning} autre(s) problème(s) urgent(s) à régler)</p>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default LockOverview;
