import React from "react";
import { Modal } from "antd";
import FormMoreInfo from "./FormMoreInfo";

const ModalMoreInfo = ({
  modalBusinessInfo,
  setModalBusinessInfo,
  submitBusiness,
  businessAddress,
}) => {
  return (
    <div>
      <Modal
        title="Dites nous en plus sur vous 😇"
        visible={modalBusinessInfo}
        onCancel={() => {
          setModalBusinessInfo(false);
        }}
        footer={null}
      >
        <FormMoreInfo
          submitBusiness={submitBusiness}
          businessAddress={businessAddress}
        />
      </Modal>
    </div>
  );
};

export default ModalMoreInfo;
