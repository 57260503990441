import React from "react";

import "./Header.css";
import logo from "./assets/logo-lr.svg";

const Header = () => {
  return (
    <div>
      <div className="navbar">
        <div className="logo">
          <a href="https://localranker.fr/?utm_source=localranker.tech">
            <img src={logo} style={{ width: "150px", marginTop: "-10px" }} />
          </a>
        </div>
        <div className="raq">
          <a
            className="typeform-share button"
            href="https://localranker.fr/?utm_source=localranker.tech&utm_campaign=header-nav"
            style={{
              display: "inline-block",
              textDecoration: "none",
              backgroundColor: "#FF4E4E",
              color: "white",
              cursor: "pointer",
              fontFamily: "Helvetica,Arial,sans-serif",
              fontSize: "14px",
              lineHeight: "35px",
              textAlign: "center",
              margin: "0",
              marginTop: "10px",
              padding: "0px 25px",
              borderRadius: "2px",
              maxWidth: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: "bold",
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Découvrir LocalRanker
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
