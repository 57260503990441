import React, { useEffect, useState } from "react";
import { Icon, Table, Skeleton, Tooltip } from "antd";
import BubbleChartCompetitors from "../BubbleChartCompetitors";
import { getCompetitorsDataForTable } from "../../../utils/competitors";

const AuditCompetitors = ({ auditInfo }) => {
  const [loading, setLoading] = useState(true);
  const [competitors, setCompetitors] = useState();
  const [companyInfo, setCompanyInfo] = useState();
  const [competitorsTable, setCompetitorsTable] = useState();

  useEffect(() => {
    const getCompetitors = async () => {
      if (auditInfo.LocalCompetitors) {
        setCompetitors(JSON.parse(auditInfo.LocalCompetitors));
        setCompetitorsTable(
          await getCompetitorsDataForTable(
            JSON.parse(auditInfo.LocalCompetitors),
            auditInfo.BusinessName
          )
        );

        setCompanyInfo({
          businessName: auditInfo.BusinessName,
          rating: auditInfo.Rating,
          nbReviews: auditInfo.Nbreviews,
        });
      } else {
        setCompetitors(null);
        setCompanyInfo(null);
      }
      setLoading(false);
    };
    getCompetitors();
  }, [
    auditInfo.LocalCompetitors,
    auditInfo.BusinessName,
    auditInfo.Rating,
    auditInfo.Nbreviews,
  ]);

  if (loading) {
    return (
      <div className="featureblock-container">
        <div className="card-header">
          <span>Analyse concurrentielle locale</span>
        </div>
        <div className="card-graph-body">
          <div className="table-competitors-audit">
            <Skeleton active />
          </div>
        </div>
      </div>
    );
  }

  if (!loading && competitors.length === 0) {
    return (
      <div className="featureblock-container">
        <div className="card-header">
          <span>Analyse concurrentielle locale</span>
        </div>
        <div className="card-graph-body">
          <div className="description-table">
            <span className="criteria-status">
              {" "}
              <span role="icon" arial-label="emoji" className="issue-icon">
                ✅
              </span>
              Nous n'avons pas pu détecter de concurrents locaux pour votre
              établissement.
            </span>
          </div>
        </div>
      </div>
    );
  }
  if (!loading && competitors) {
    const dataSource = [
      {
        key: "1",
        name: `${auditInfo.BusinessName} (Vous)`,
        averageRating: `${auditInfo.Rating}`,
        averageReviews: `${auditInfo.Nbreviews}`,
        category: `${
          auditInfo.Industry ? auditInfo.Industry : "Pas de catégorie"
        }`,
      },
      {
        key: "2",
        name: `Concurrents (${competitors.length})`,
        averageRating: `${auditInfo.AverageRatingCompetitors}`,
        averageReviews: `${auditInfo.AverageReviewsCompetitors}`,
        category: `-`,
        children: competitorsTable,
      },
    ];

    const columns = [
      {
        title: "Point de vente",
        dataIndex: "name",
        key: "name",
        render: (text) => (
          <b style={{ color: "rgba(0, 0, 0, 0.85)" }}>{text}</b>
        ),
      },
      {
        title: "Note moyenne",
        dataIndex: "averageRating",
        render: (text, record) => (
          <b style={{ color: "rgba(0, 0, 0, 0.85)" }}>
            {text} <Icon className="star" type="star" theme="filled" />
          </b>
        ),
      },
      {
        title: "Nombre d'avis moyen",
        dataIndex: "averageReviews",
        defaultSortOrder: "descend",
        sortOrder: "descend",
      },
      {
        title: "Catégorie",
        dataIndex: "category",
      },
    ];

    return (
      <div className="featureblock-container">
        <div className="card-header">
          <span>Analyse concurrentielle locale</span>
        </div>
        <div className="card-graph-body">
          <div className="description-table">
            {auditInfo.Nbreviews > auditInfo.AverageReviewsCompetitors &&
            auditInfo.Rating > auditInfo.AverageRatingCompetitors ? (
              <span className="criteria-status">
                {" "}
                <span role="icon" arial-label="emoji" className="issue-icon">
                  ✅
                </span>{" "}
                Vous êtes mieux noté et avez plus d'avis en moyenne que vos
                concurrents locaux.
              </span>
            ) : (
              ""
            )}
            {auditInfo.Nbreviews > auditInfo.AverageReviewsCompetitors &&
            auditInfo.Rating < auditInfo.AverageRatingCompetitors ? (
              <span className="criteria-status">
                {" "}
                <span role="icon" arial-label="emoji" className="issue-icon">
                  ✅
                </span>
                Vous avez plus d'avis en moyenne que vos concurrents mais ils
                sont mieux notés.
              </span>
            ) : (
              ""
            )}
            {auditInfo.Nbreviews < auditInfo.AverageReviewsCompetitors &&
            auditInfo.Rating > auditInfo.AverageRatingCompetitors ? (
              <span className="criteria-status">
                {" "}
                <span role="icon" arial-label="emoji" className="issue-icon">
                  ❌
                </span>
                Vous avez moins d'avis que vos concurrents mais vous êtes mieux
                notés.
              </span>
            ) : (
              ""
            )}
            {auditInfo.Nbreviews < auditInfo.AverageReviewsCompetitors &&
            auditInfo.Rating < auditInfo.AverageRatingCompetitors ? (
              <span className="criteria-status">
                {" "}
                <span role="icon" arial-label="emoji" className="issue-icon">
                  ❌
                </span>
                Vous avez moins d'avis que vos concurrents et êtes moins bien
                notés.
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="details-competitors-audit">
            <span className="position-mapping">
              Carte de positionnement des concurrents locaux{"  "}
            </span>
            <Tooltip title="La carte de positionnement des concurrents locaux représente les forces et faiblesses de vos concurrents (avis et note moyenne). Plus le cercle est grand, plus l'établissement a d'avis et de visibilité.">
              <Icon type="info-circle" />
            </Tooltip>
            <div className="chart-competitors">
              <BubbleChartCompetitors
                competitors={competitors}
                companyInfo={companyInfo}
              />
              <div className="legend-graph">
                <div className="legend-square" />
                {"  "}
                <span>{auditInfo.BusinessName} (Vous)</span>
              </div>
            </div>
          </div>
          <div className="table-competitors-audit">
            <span className="position-mapping">
              Comparaison de la visibilité locale{"  "}
            </span>
            <Tooltip title="Le tableau de comparaison de la visiblité locale compare votre établissement par rapport aux établissements similaires autour de vous. Essayez d'être mieux noté et avec plus d'avis pour attirer le plus de clients.">
              <Icon type="info-circle" />
            </Tooltip>
            <br />
            <br />
            <Table
              className="competitors-table"
              columns={columns}
              dataSource={dataSource}
              rowKey={(record) => record.name}
            />
          </div>
        </div>
      </div>
    );
  }
};
export default AuditCompetitors;
