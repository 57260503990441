import React from "react";

import "./Hero.css";

const Hero = ({ title, subtitle, emoji }) => {
  return (
    <div>
      <h1 className="main-title">
        {title}
        <span role="img" aria-label="emoji">
          {emoji}
        </span>
      </h1>
      <h2 className="secondary-title">{subtitle}</h2>
    </div>
  );
};

export default Hero;
