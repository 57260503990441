import React from "react";

const PromotionnalBanner = () => {
  return (
    <div className="container">
      <div className="companyInfo">
        <div className="title-card">
          <span>⭐ Améliorez votre référencement local</span>
        </div>
        <div className="body-card">
          <span>
            Testez LocalRanker, l'outil n°1 pour optimiser votre référencement
            local et gérer votre e-réputation.
          </span>
        </div>
        <div className="cta-card">
          <a
            href="https://localranker.fr/?utm_source=localranker.tech/search-location&utm_campaign=banner-bottom"
            target="_blank"
            className="new-search-button"
          >
            Découvrir LocalRanker
          </a>
        </div>
      </div>
    </div>
  );
};

export default PromotionnalBanner;
