import React, { useState } from "react";

import Hero from "../../components/Hero/Hero";

import { message, Icon } from "antd";
import FormLocalSearch from "../../components/FormLocalSearch/FormLocalSearch";
import ResultLocalSearch from "../../components/ResultLocalSearch/ResultLocalSearch";
// import { LoaderDots } from "@thumbtack/thumbprint-react";
import PromotionnalBanner from "../../components/PromotionalBanner/PromotionnalBanner";
import { Helmet } from "react-helmet";
const API_URL = process.env.REACT_APP_API_URL || "http://localhost:4000/tech/";

const SearchLocationCheck = () => {
  const [displayFormSearch, setDisplaySearch] = useState(true);
  const [displaySearchLinks, setDisplaySearchLinks] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paramResults, setParamResults] = useState({});
  const [modalBusinessInfo, setModalBusinessInfo] = useState(false);

  const handleOpenModalBusinessInfo = (businessAddress) => {
    setModalBusinessInfo(true);
  };

  const onLocalSearchSubmit = async (LocalSearch, data) => {
    setIsLoading(true);
    setDisplaySearch(false);

    fetch(`${API_URL}get-uule?`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        location: LocalSearch.location,
        term: LocalSearch.searchTerm,
        user: data,
      }),
    })
      // We get the API response and receive data in JSON format...
      .then((response) => response.json())
      // ...then we update the users state
      .then(async (uule) => {
        let paramLinks = {
          SearchTerm: LocalSearch.searchTerm,
          SearchType: LocalSearch.searchType,
          Location: LocalSearch.location,
          Country: LocalSearch.country,
          Uule: uule,
        };
        setParamResults(paramLinks);
        setIsLoading(false);
        setDisplaySearchLinks(true);
      });
  };

  return (
    <div style={{ padding: "0px 0px 30px 0px" }}>
      <Helmet>
        <title>
          Analyser les résultats Google hyper localisé | LocalRanker
        </title>
        <meta
          name="description"
          content="Vous vous demandez si vous êtes bien référencé dans une certaine localisation ? Vérifiez cela avec notre outil gratuit !"
        />
      </Helmet>
      <Hero
        title="Analyser les resultats de recherche locale"
        subtitle="Découvrez en un clin d'oeil les résultats de recherche Google de façon hyper localisé"
        emoji=" 📈"
      />
      {displayFormSearch ? (
        <FormLocalSearch onLocalSearchSubmit={onLocalSearchSubmit} />
      ) : (
        ""
      )}
      {isLoading ? (
        <div className="loading-message-serp">
          <span style={{ display: "inline-block" }}>
            ♻️ Récupération des données
            <Icon
              type="loading"
              style={{ fontSize: "20px", marginLeft: "20px" }}
            />
          </span>
        </div>
      ) : (
        ""
      )}
      {displaySearchLinks ? (
        <>
          <ResultLocalSearch paramLinks={paramResults} />
          <PromotionnalBanner />
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default SearchLocationCheck;
