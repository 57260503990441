export const getCompetitorsDataForTable = async (competitors, companyInfo) => {
  let results = [];
  for (const competitor of competitors) {
    if (competitor.businessName !== companyInfo) {
      let object = {
        key: getRandomInt(),
        name: competitor.businessName,
        averageRating: competitor.rating,
        averageReviews: competitor.nbReviews,
        category: `${
          competitor.category === null
            ? "Pas de catégorie"
            : competitor.category
        }`,
      };
      results.push(object);
    }
  }
  return results.sort((a, b) => b.averageReviews - a.averageReviews);
};

export const getCompetitorsDataForBubble = async (
  competitors,
  limit,
  companyData
) => {
  // sort competitors by nb of reviews
  let datasets = [];
  let competitor;
  if (competitors.length >= 10) {
    competitor = competitors
      .sort((a, b) => b.nbReviews - a.nbReviews)
      .slice(0, limit);
  } else {
    competitor = competitors.sort((a, b) => b.nbReviews - a.nbReviews);
  }
  if (
    competitor.some(
      (company) => company.businessName === companyData.businessName
    )
  ) {
    // do nothing
  } else {
    competitor.push({
      businessName: companyData.businessName,
      rating: companyData.rating,
      nbReviews: companyData.nbReviews,
    });
  }
  let max = Math.max.apply(
    Math,
    competitor.map(function (o) {
      return o.nbReviews;
    })
  );
  let min = Math.min.apply(
    Math,
    competitor.map(function (o) {
      return o.nbReviews;
    })
  );

  for (const company of competitor) {
    const radius = await getRadius(company.nbReviews, max, min);
    let color1, color2, color3;
    if (company.businessName === companyData.businessName) {
      color1 = 255;
      color2 = 78;
      color3 = 78;
    } else {
      color1 = await getRandomInt();
      color2 = await getRandomInt();
      color3 = await getRandomInt();
    }
    const dataset = {
      label: `${company.businessName}`,
      fill: false,
      lineTension: 0.1,
      backgroundColor: `rgba(${color1},${color2},${color3},0.6)`,
      borderColor: `rgba(${color1},${color2},${color3},1)`,
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "rgba(75,192,192,1)",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(75,192,192,1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [{ x: company.rating, y: company.nbReviews, r: radius }],
    };
    datasets.push(dataset);
  }
  return datasets;
};

export const getRandomInt = () => {
  return Math.floor(Math.random() * Math.floor(200));
};

const getRadius = (reviews, max, min) => {
  let xMax = 30;
  let xMin = 5;

  let yMax = max;
  let yMin = min;

  let percent = (reviews - yMin) / (yMax - yMin);
  let outputX = percent * (xMax - xMin) + xMin;
  return outputX;
};
