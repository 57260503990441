export const parseBusinessFromDBToValidFormat = (business) => {
  const valid = [];
  const issues = [];
  if (business.Duplicates) {
    let duplicates = JSON.parse(business.Duplicates);
    if (duplicates.length > 0) {
      issues.push({
        header: `${duplicates.length} doublons potentiels détectés`,
        content:
          "Un doublon signifit qu'une fiche GMB ayant des informations similaires aux votres a été détecté. Cela peut hurter gravement votre e-réputation. Rendez-vous dans la section doublon pour plus de détails.",
      });
    }
  }
  if (business.IsCompanyClaimed) {
    valid.push({
      header: "Établissement revendiqué",
      content:
        "Revendiquez votre fiche Google my Business est la première étape pour votre SEO local. Si vous n’avez pas revendiqué votre GMB, vous ne pourrez pas optimiser votre fiche. Lorsque vous revendiquez votre fiche GMB, vous pouvez modifier vos informations. Si votre fiche n’est pas créé vous devez suivre la procédure pour valider votre établissement.",
    });
  } else {
    issues.push({
      header: "Établissement non revendiqué",
      content:
        "Revendiquez votre fiche Google my Business est la première étape pour votre SEO local. Si vous n’avez pas revendiqué votre GMB, vous ne pourrez pas optimiser votre fiche. Lorsque vous revendiquez votre fiche GMB, vous pouvez modifier vos informations. Si votre fiche n’est pas créé vous devez suivre la procédure pour valider votre établissement.",
    });
  }
  if (business.Address) {
    valid.push({
      header: "Adresse renseignée",
      content:
        "Mettre une adresse si vous avez un établissement physique. Google favorise les fiches avec adresse car cela montre que vous êtes un vrai établissement et non un spam.",
    });
  } else {
    issues.push({
      header: "Adresse non renseignée",
      content:
        "Mettre une adresse si vous avez un établissement physique. Google favorise les fiches avec adresse car cela montre que vous êtes un vrai établissement et non un spam.",
    });
  }
  if (business.Website) {
    valid.push({
      header: "Site web renseigné",
      content:
        "Vous devez mettre la bonne adresse de votre site web. Ne pas utiliser votre page facebook ou linkedin dans cette section.",
    });
  } else {
    issues.push({
      header: "Site web non renseigné",
      content:
        "Vous devez mettre la bonne adresse de votre site web. Ne pas utiliser votre page facebook ou linkedin dans cette section.",
    });
  }
  if (business.Phone) {
    valid.push({
      header: "Téléphone renseigné",
      content:
        "Mettez votre numéro de téléphone principal et évitez qu’il soit changé.",
    });
  } else {
    issues.push({
      header: "Téléphone non renseigné",
      content:
        "Mettez votre numéro de téléphone principal et évitez qu’il soit changé.",
    });
  }
  if (business.HasReviews) {
    valid.push({
      header: "Objectif premier avis",
      content:
        "Vous avez obtenu votre premier avis, c'est super ! 🙌 Ne vous arrêtez pas en si bon chemin ! Vous devez améliorer votre e-réputation et votre seo local grâce aux avis. Avoir des avis vous permet d’avoir plus de visibilité sur Google. Les gens prennent de plus en plus une décision d’achat grâce aux avis. Une étude montre qu’avoir plus d’avis sur Google My Business peut augmenter vos conversions de 20%.",
    });
  } else {
    issues.push({
      header: `Objectif premier avis`,
      content:
        "Vous n'avez aucun avis sur votre fiche GMB, ce n'est pas terrible 😢 ! Vous devez améliorer votre e-réputation et votre seo local grâce aux avis. Avoir des avis vous permet d’avoir plus de visibilité sur Google. Les gens prennent de plus en plus une décision d’achat grâce aux avis. Une étude montre qu’avoir plus d’avis sur Google My Business peut augmenter vos conversions de 20%.",
    });
  }
  if (business.Nbreviews > 20) {
    valid.push({
      header: "Vous avez plus de 20 avis",
      content:
        "Vous avez déjà plus de 20 avis, ce qui est un bon point. Cependant, nous vous conseillons de demander à vos clients plus d'avis ! Pour se faire, retrouvez dans la catégorie Avis quelques astuces ⭐️",
    });
  } else {
    issues.push({
      header: "Vous n'avez pas assez d'avis",
      content:
        "Vous avez très peu d'avis, il va falloir corriger cela. Nous vous conseillons de demander à vos clients plus d'avis ! Pour se faire, retrouvez dans la catégorie Avis quelques astuces ⭐️",
    });
  }
  if (business.IsBusinessHoursFilled) {
    valid.push({
      header: "Horaires renseignés",
      content:
        "Vos horaires d’ouvertures et fermetures doivent être visible pour informer vos clients  de vos disponibilités. Ne vous trompez pas d’heure cela pourrait frustrer certains visiteurs.",
    });
  } else {
    issues.push({
      header: "Horaires non renseignés",
      content:
        "Vos horaires d’ouvertures et fermetures doivent être visible pour informer vos clients  de vos disponibilités. Ne vous trompez pas d’heure cela pourrait frustrer certains visiteurs.",
    });
  }
  if (business.IsQandAFilled) {
    valid.push({
      header: "FAQ renseignée",
      content: `Créez un FAQ pour votre établissement.
    La FAQ permet aux internautes de poser des questions directement à l’entreprise. Elle est affichée au public donc il faut faire attention à ce que vos clients mettent dessus et bien y répondre.
    Mais, l’entreprise peut aussi créer ses propres questions et y répondre. La FAQ, vous évite d’avoir des demandes inutiles par téléphone et améliorer votre taux de clics.
    `,
    });
  } else {
    issues.push({
      header: "FAQ non renseignée",
      content: `Créez un FAQ pour votre établissement.
    La FAQ permet aux internautes de poser des questions directement à l’entreprise. Elle est affichée au public donc il faut faire attention à ce que vos clients mettent dessus et bien y répondre.
    Mais, l’entreprise peut aussi créer ses propres questions et y répondre. La FAQ, vous évite d’avoir des demandes inutiles par téléphone et améliorer votre taux de clics.
    `,
    });
  }
  if (business.MerchandDescription) {
    valid.push({
      header: "Description renseignée",
      content:
        "Ecrivez une petite description pour votre entreprise avec votre mot clé principal et votre zone géographique. N’hésitez pas à mettre en avant ce qui vous distingue des autres concurrents. Ne pas sur-optimiser votre texte, c’est à dire ne pas répéter plusieurs fois vos mots clés.",
    });
  } else {
    issues.push({
      header: "Description non renseignée",
      content:
        "Ecrivez une petite description pour votre entreprise avec votre mot clé principal et votre zone géographique. N’hésitez pas à mettre en avant ce qui vous distingue des autres concurrents. Ne pas sur-optimiser votre texte, c’est à dire ne pas répéter plusieurs fois vos mots clés.",
    });
  }
  if (business.NbofPictures > 5) {
    valid.push({
      header: "Vous avez assez de photos",
      content:
        "Ajoutez au moins 5 photos de votre établissement sur votre fiche Google My Business. Vos photos doivent montrer votre établissement de l’extérieur, intérieur, votre équipe et vos produits. Privilégiez les photos de bonnes qualités et ne pas utilisez des photos de banques d’images. Google favorise les photos de lieux et de gens. Les photos aident les internautes à prendre une décision selon une étude.",
    });
  } else {
    issues.push({
      header: `Vous n'avez pas assez de photos`,
      content:
        "Ajoutez au moins 5 photos de votre établissement sur votre fiche Google My Business. Vos photos doivent montrer votre établissement de l’extérieur, intérieur, votre équipe et vos produits. Privilégiez les photos de bonnes qualités et ne pas utilisez des photos de banques d’images. Google favorise les photos de lieux et de gens. Les photos aident les internautes à prendre une décision selon une étude.",
    });
  }
  if (business.HasPosts) {
    valid.push({
      header: "Vous avez des Google Posts",
      content: `Publiez des Google posts.
        Google posts vous permet d’avoir plus de visibilité et informer vos clients sur votre actualité. Ils vous donnent une meilleure visibilité et donne la possibilité d’attirer plus de clients dans votre établissement. Les Google posts sont visible sur les résultats de recherches sous votre fiche et améliore votre taux de clics. Continuez à publier !`,
    });
  } else {
    issues.push({
      header: `Vous n'avez pas de Google Posts récents`,
      content: `Publiez des Google posts.
      Google posts vous permet d’avoir plus de visibilité et informer vos clients sur votre actualité. Ils vous donnent une meilleure visibilité et donne la possibilité d’attirer plus de clients dans votre établissement. Les Google posts sont visible sur les résultats de recherches sous votre fiche et améliore votre taux de clics. Continuez à publier !`,
    });
  }
  if (business.Rating >= 4) {
    valid.push({
      header: "Votre note moyenne est bonne",
      content:
        "Toujours avoir une note supérieure ou égale à 4 / 5. Des études montrent que avoir une bonne note générale améliorer votre taux de clics. Un établissement avec 5 étoiles recevra plus de clics qu’un établissement avec 2 étoiles.",
    });
  } else {
    issues.push({
      header: `Votre note moyenne n'est pas bonne`,
      content:
        "Toujours avoir une note supérieure ou égale à 4 / 5. Des études montrent que avoir une bonne note générale améliorer votre taux de clics. Un établissement avec 5 étoiles recevra plus de clics qu’un établissement avec 2 étoiles.",
    });
  }
  if (business.HasProducts) {
    valid.push({
      header: "Produits renseignés",
      content: `Publiez vos produits et services sur Google. 
      Certains établissements peuvent montrer leurs produits ou services avec une description et les prix sur les résultats de recherches. 
      Ils seront visibles sous la fiche de l’établissement.
       Lorsque vous avez renseignez vos produits/services, vous améliorez votre taux de clics et les conversions sur votre fiche.`,
    });
  } else {
    issues.push({
      header: "Aucun produit renseigné",
      content: `Publiez vos produits et services sur Google. 
      Certains établissements peuvent montrer leurs produits ou services avec une description et les prix sur les résultats de recherches. 
      Ils seront visibles sous la fiche de l’établissement.
       Lorsque vous avez renseignez vos produits/services, vous améliorez votre taux de clics et les conversions sur votre fiche.`,
    });
  }
  if (business.Has360ExteriorPicture) {
    valid.push({
      header: "Photo 360° Extérieur renseignée",
      content:
        "Vous devez avoir une photo 360° extérieure visible sur votre fiche Google My Business. Ces photos 360° sont visibles sur Google Maps et aident Google à mieux déterminer votre localisation et votre légitimité.",
    });
  } else {
    issues.push({
      header: "Aucune photo 360° extérieure",
      content:
        "Vous devez avoir une photo 360° visible sur votre fiche Google My Business. Ces photos 360° sont visibles sur Google Maps et aident Google à mieux déterminer votre localisation et votre légitimité.",
    });
  }
  if (business.Has360InteriorPicture) {
    valid.push({
      header: "Photo 360° Intérieur renseignée",
      content:
        "A la différence de la photo 360° extérieure, la photo 360° intérieure doit n'est pas ajoutée automatiquement à votre établissement. Vous pouvez si vous le désirez, embaucher un photographe aggrégé Google afin d'obtenir cette photo 360° de votre point de vente. Elle permettra aux futurs clients de mieux se projeter dans votre établissement.",
    });
  } else {
    issues.push({
      header: "Aucune photo 360° intérieure",
      content:
        "A la différence de la photo 360° extérieure, la photo 360° intérieure doit n'est pas ajoutée automatiquement à votre établissement. Vous pouvez si vous le désirez, embaucher un photographe aggrégé Google afin d'obtenir cette photo 360° de votre point de vente. Elle permettra aux futurs clients de mieux se projeter dans votre établissement.",
    });
  }
  if (business.HasFreshMedia) {
    valid.push({
      header: "Contenu média récent",
      content:
        "Vous êtes très bon, vous postez des photos régulièrement ! 🥰 Il est important pour vos futurs clients de voir des photos récentes de votre point de vente. Cela montrera le dynamisme de votre établissement.",
    });
  } else {
    issues.push({
      header: "Contenu média ancien",
      content:
        "Ajoutez des photos, vidéos régulièrement à votre fiche GMB. Il est important pour vos futurs clients de voir des photos récentes de votre point de vente. Cela montrera le dynamisme de votre établissement.",
    });
  }
  if (business.HasVideos) {
    valid.push({
      header: "Au moins 1 vidéo détectée",
      content:
        "Les vidéos sont des éléments intéressants dans l'optimisation de votre fiche Google My Business. Les vidéos vont inciter les internautes à prendre leur décision de se rendre sur le point de vente ou non. Il est donc recommandé d'avoir quelques vidéos sur votre fiche.",
    });
  } else {
    issues.push({
      header: "Aucun vidéo détectée",
      content:
        "Veuillez ajouter une ou des vidéos sur votre fiche. Les vidéos sont des éléments intéressants dans l'optimisation de votre fiche Google My Business. Les vidéos vont inciter les internautes à prendre leur décision de se rendre sur le point de vente ou non. Il est donc recommandé d'avoir quelques vidéos sur votre fiche.",
    });
  }

  const parsedCB = {
    business,
    valid,
    issues,
  };
  return parsedCB;
};
