import React, { useEffect, useState } from "react";
import { Row, Col, Skeleton } from "antd";

import noPicture from "../../FormLocalSearch/assets/audit/pas-de-photos.png";
import exteriorGif from "../../FormLocalSearch/assets/audit/view-exterior.gif";
import interiorGif from "../../FormLocalSearch/assets/audit/view-interior.gif";
import ProgressCircle from "../ProgressCircle";

const Medias = ({ auditInfo }) => {
  const [score, setScore] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getScore = async () => {
      let score = 0;
      if (auditInfo.Has360ExteriorPicture) {
        score = score + 20;
      }
      if (auditInfo.Has360InteriorPicture) {
        score = score + 20;
      }
      if (auditInfo.HasVideos) {
        score = score + 20;
      }
      if (auditInfo.HasFreshMedia) {
        score = score + 20;
      }
      if (auditInfo.NbofPictures > 5) {
        score = score + 20;
      }
      setScore(score);
      setLoading(false);
    };
    getScore();
  }, [
    auditInfo.Has360ExteriorPicture,
    auditInfo.Has360InteriorPicture,
    auditInfo.HasVideos,
    auditInfo.HasFreshMedia,
    auditInfo.NbofPictures,
  ]);

  if (loading) {
    return (
      <>
        <div className="featureblock-container">
          <div className="card-header">
            <span>Indispensables</span>
          </div>
          <div className="card-graph-body">
            <div className="sumup-category">
              <Skeleton active />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="featureblock-container">
        <div className="card-header">
          <span>Médias</span>
        </div>
        <div className="card-graph-body">
          <div className="sumup-category">
            <Row>
              <Col xl={10} lg={10} sm={24} className="progress-category">
                <ProgressCircle type="circle" score={score} />
              </Col>
              <Col xl={14} lg={14} sm={24}>
                <div style={{ marginTop: "20px" }}>
                  Les médias sont importants pour la fiche Google my Business,
                  ils sont le reflet de votre établissement. N’oubliez pas
                  d’utiliser des photos de bonnes qualités pour donner envie aux
                  internautes de vous contacter.
                </div>
              </Col>
            </Row>
          </div>
          <div className="criteria-audit">
            <Row gutter={30}>
              <Col xl={14} lg={14} sm={24}>
                <div className="criteria-status">
                  <span>
                    {auditInfo.NbofPictures > 5 ? (
                      <>
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ✅
                        </span>
                        Nombre de photos minimum suffisant{" "}
                      </>
                    ) : (
                      <>
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ❌
                        </span>
                        Nombre de photos minimum insuffisant
                      </>
                    )}
                  </span>
                </div>
                <div className="criteria-description">
                  <span>
                    Il est important d'avoir au moins 5 photos de votre
                    établissement sur votre fiche Google My Business.
                    <br />
                    <br />
                    Vos photos doivent montrer votre établissement de
                    l’extérieur, intérieur, votre équipe et vos produits.
                    <br />
                    <br />
                    <b>
                      Privilégiez les photos de bonnes qualités et ne pas
                      utilisez des photos de banques d’images, pas de montage et
                      pas de GIF.
                    </b>
                    <br />
                    <br />
                    Google favorise les photos de lieux et de gens. Les photos
                    aident les internautes à prendre une décision selon une
                    étude.
                  </span>
                </div>
              </Col>
              <Col xl={10} lg={10} sm={24}>
                <img src={noPicture} width="100%" alt="no icon" />
              </Col>
            </Row>
          </div>
          <div className="criteria-audit">
            <Row gutter={30}>
              <Col xl={14} lg={14} sm={24}>
                <div className="criteria-status">
                  <span>
                    {auditInfo.Has360ExteriorPicture ? (
                      <>
                        {" "}
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ✅
                        </span>
                        Photo 360° extérieure présente{" "}
                      </>
                    ) : (
                      <>
                        {" "}
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ❌
                        </span>
                        Pas de photo 360° extérieure
                      </>
                    )}
                  </span>
                </div>
                <div className="criteria-description">
                  <span>
                    La photo 360° extérieure est importante pour les internautes
                    et vos futurs clients.
                    <br />
                    <br />
                    Elle donne une idée de la localisation de votre
                    établissement grâce à google maps.
                  </span>
                </div>
              </Col>
              <Col xl={10} lg={10} sm={24}>
                <img src={exteriorGif} width="100%" alt="gif exterior" />
              </Col>
            </Row>
          </div>
          <div className="criteria-audit">
            <Row gutter={30}>
              <Col xl={14} lg={14} sm={24}>
                <div className="criteria-status">
                  <span>
                    {auditInfo.Has360InteriorPicture ? (
                      <>
                        {" "}
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ✅
                        </span>
                        Photo 360° intérieure présente{" "}
                      </>
                    ) : (
                      <>
                        {" "}
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ❌
                        </span>
                        Pas de photo 360° intérieure
                      </>
                    )}
                  </span>
                </div>
                <div className="criteria-description">
                  <span>
                    La photo 360° intérieure est rare et permet aux futurs
                    visiteurs de se projetter dans votre point de vente.
                    <br />
                    <br />
                    La photo 360° commence petit à petit à se démocratiser en
                    France alors n'attendez pas plus longtemps pour vous
                    démarquez !
                  </span>
                </div>
              </Col>
              <Col xl={10} lg={10} sm={24}>
                <img src={interiorGif} width="100%" alt="gif interior" />
              </Col>
            </Row>
          </div>
          <div className="criteria-audit">
            <Row gutter={30}>
              <Col xl={14} lg={14} sm={24}>
                <div className="criteria-status">
                  <span>
                    {auditInfo.HasFreshMedia ? (
                      <>
                        {" "}
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ✅
                        </span>
                        Contenu média récent{" "}
                      </>
                    ) : (
                      <>
                        {" "}
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ❌
                        </span>
                        Contenu média ancien
                      </>
                    )}
                  </span>
                </div>
                <div className="criteria-description">
                  <span>
                    Avoir un contenu média toujours frais est important pour
                    votre clientèle.
                    <br />
                    <br />
                    Poster régulièrement du contenu sur votre fiche GMB va
                    montrer le dynamisme de votre établissement, un grand atout
                    par rapport aux concurrents.
                  </span>
                </div>
              </Col>
              <Col xl={10} lg={10} sm={24}></Col>
            </Row>
          </div>
          <div className="criteria-audit">
            <Row gutter={30}>
              <Col xl={14} lg={14} sm={24}>
                <div className="criteria-status">
                  <span>
                    {auditInfo.HasVideos ? (
                      <>
                        {" "}
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ✅
                        </span>
                        Au moins une vidéo détectée{" "}
                      </>
                    ) : (
                      <>
                        {" "}
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ❌
                        </span>
                        Aucune vidéo détectée
                      </>
                    )}
                  </span>
                </div>
                <div className="criteria-description">
                  <span>
                    Les vidéos sont des éléments intéressants dans
                    l'optimisation de votre fiche Google My Business.
                    <br />
                    <br />
                    Les vidéos vont inciter les internautes à prendre leur
                    décision de se rendre sur le point de vente ou non.
                    <br />
                    <br />
                    Il est donc recommandé d'avoir quelques vidéos sur votre
                    fiche.
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
};

export default Medias;
