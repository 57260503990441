import React from "react";
import { Icon, Row, Col, Collapse } from "antd";
import "./GMBScoring.css";
import LockOverview from "./LockOverview";

const { Panel } = Collapse;

const TopIssues = ({ issues, hasFullAccess, handleNewUnlockStripe }) => {
  return (
    <div className="issues-container">
      <h3>
        <span role="img" aria-label="emoji">
          ❌
        </span>{" "}
        Problèmes détectés ({issues.length})
      </h3>
      <Row gutter={30}>
        <Col span={24}>
          <div className="issues-list">
            {issues.length === 0 && <span>Aucun problème détecté</span>}
            {issues.length > 0 && !hasFullAccess ? (
              <>
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1"]}
                  style={{ textAlign: "left", color: "#00000" }}
                >
                  {issues.slice(0, 3).map((issue) => {
                    return (
                      <Panel
                        header={issue.header}
                        extra={<Icon type="warning" />}
                        key={issue.header}
                      >
                        <p style={{ paddingLeft: 24 }}>{issue.content}</p>
                      </Panel>
                    );
                  })}
                </Collapse>
                <LockOverview
                  handleNewUnlockStripe={handleNewUnlockStripe}
                  isIssue={true}
                  issuesRemaning={issues.length - 3}
                />
              </>
            ) : (
              ""
            )}
            {issues.length > 0 && hasFullAccess ? (
              <>
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1"]}
                  style={{ textAlign: "left", color: "#00000" }}
                >
                  {issues.map((issue) => {
                    return (
                      <Panel
                        header={issue.header}
                        extra={<Icon type="warning" />}
                        key={issue.header}
                      >
                        <p style={{ paddingLeft: 24 }}>{issue.content}</p>
                      </Panel>
                    );
                  })}
                </Collapse>
              </>
            ) : (
              ""
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TopIssues;
