import React, { useEffect, useState } from "react";
import { Row, Col, Skeleton } from "antd";

import productsPicture from "../../FormLocalSearch/assets/audit/Produits-renseignés.png";
import postsPicture from "../../FormLocalSearch/assets/audit/google-posts.jpg";
import FAQ from "../../FormLocalSearch/assets/audit/FAQ.png";
import ProgressCircle from "../ProgressCircle";

const Engagement = ({ auditInfo }) => {
  const [score, setScore] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getScore = async () => {
      let score = 0;
      if (auditInfo.HasProducts) {
        score = score + 34;
      }
      if (auditInfo.HasPosts) {
        score = score + 33;
      }
      if (auditInfo.IsQandAFilled) {
        score = score + 33;
      }
      setScore(score);
      setLoading(false);
    };
    getScore();
  }, [auditInfo.HasProducts, auditInfo.HasPosts, auditInfo.IsQandAFilled]);

  if (loading) {
    return (
      <>
        <div className="featureblock-container">
          <div className="card-header">
            <span>Indispensables</span>
          </div>
          <div className="card-graph-body">
            <div className="sumup-category">
              <Skeleton active />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="featureblock-container">
        <div className="card-header">
          <span>Engagement</span>
        </div>
        <div className="card-graph-body">
          <div className="sumup-category">
            <Row>
              <Col xl={10} lg={10} sm={24} className="progress-category">
                <ProgressCircle type="circle" score={score} />
              </Col>
              <Col xl={14} lg={14} sm={24}>
                <div style={{ marginTop: "20px" }}>
                  Voici quelques conseils et critères supplémentaires pour
                  optimiser votre fiche Google My Business. Ces éléments sont
                  des nouveautés Google My Business toujours intéressants à
                  utiliser pour dépasser la concurrence.
                </div>
              </Col>
            </Row>
          </div>
          <div className="criteria-audit">
            <Row gutter={30}>
              <Col xl={14} lg={14} sm={24}>
                <div className="criteria-status">
                  <span>
                    {auditInfo.HasProducts ? (
                      <>
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ✅
                        </span>
                        Produit ou service renseigné{" "}
                      </>
                    ) : (
                      <>
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ❌
                        </span>
                        Aucun produit ou service renseigné
                      </>
                    )}
                  </span>
                </div>
                <div className="criteria-description">
                  <span>
                    Publiez vos produits et services sur Google. <br />
                    Certains établissements peuvent montrer leurs produits ou
                    services avec une description et les prix sur les résultats
                    de recherches.
                    <br />
                    <br />
                    Ils seront visibles sous la fiche de l’établissement.
                    Lorsque vous avez renseignez vos produits/services, vous
                    améliorez votre taux de clics et les conversions sur votre
                    fiche.
                    <br />
                  </span>
                </div>
              </Col>
              <Col xl={10} lg={10} sm={24}>
                <img src={productsPicture} width="80%" alt="prodcuts" />
              </Col>
            </Row>
          </div>
          <div className="criteria-audit">
            <Row gutter={30}>
              <Col xl={14} lg={14} sm={24}>
                <div className="criteria-status">
                  <span>
                    {auditInfo.HasPosts ? (
                      <>
                        {" "}
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ✅
                        </span>
                        Google Posts récents{" "}
                      </>
                    ) : (
                      <>
                        {" "}
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ❌
                        </span>
                        Aucun Google Post récent
                      </>
                    )}
                  </span>
                </div>
                <div className="criteria-description">
                  <span>
                    Il est conseillé de publier des Google posts régulièrement.
                    (2 fois par mois minimum)
                    <br />
                    <br />
                    Google posts vous permet d’avoir plus de visibilité et
                    informer vos clients sur votre actualité.
                    <br />
                    Ils vous donnent une meilleure visibilité et donne la
                    possibilité d’attirer plus de clients dans votre
                    établissement. Les Google posts sont visibles sur les
                    résultats de recherches sous votre fiche et améliore votre
                    taux de clics.
                    <br />
                    <br />
                    Vos 30 premiers mots doivent être vendeur, le visuel doit
                    respecter les consignes Google, mettre un CTA pour inciter
                    les internautes à l'action.
                  </span>
                </div>
              </Col>
              <Col xl={10} lg={10} sm={24}>
                {" "}
                <img src={postsPicture} width="90%" alt="google posts" />
              </Col>
            </Row>
          </div>
          <div className="criteria-audit">
            <Row gutter={30}>
              <Col xl={14} lg={14} sm={24}>
                <div className="criteria-status">
                  <span>
                    {auditInfo.IsQandAFilled ? (
                      <>
                        {" "}
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ✅
                        </span>
                        FAQ renseignée{" "}
                      </>
                    ) : (
                      <>
                        {" "}
                        <span
                          role="img"
                          className="issue-icon"
                          aria-label="emoji"
                        >
                          ❌
                        </span>
                        Aucune FAQ renseignée
                      </>
                    )}
                  </span>
                </div>
                <div className="criteria-description">
                  <span>
                    Avoir une FAQ pour votre établissement est très utile pour
                    les internautes et peut vous faire gagner un temps précieux.
                    <br />
                    <br />
                    La FAQ permet aux internautes de poser des questions
                    directement à l’entreprise. Elle est affichée au public donc
                    il faut faire attention à ce que vos clients mettent dessus
                    et bien y répondre.
                    <br />
                    <br />
                    L’entreprise peut aussi créer ses propres questions et y
                    répondre.
                    <br />
                    <br />
                    <b>
                      La FAQ, vous évite d’avoir des demandes inutiles par
                      téléphone et améliorer votre taux de clics.
                    </b>
                  </span>
                </div>
              </Col>
              <Col xl={10} lg={10} sm={24}>
                <img
                  src={FAQ}
                  width="90%"
                  style={{ marginTop: 80 }}
                  alt="FAQ"
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
};

export default Engagement;
