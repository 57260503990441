import React, { useEffect, useState } from "react";

import "./ResultLocalSearch.css";
// import { Progress, Collapse, Avatar, Row, Col } from "antd";
import pagnination from "./assets/google-pagintation.png";

const ResultLocalSearch = ({ paramLinks }) => {
  const [link, setLink] = useState();
  const [isMaps, setIsMaps] = useState(false);
  useEffect(() => {
    async function generateLink() {
      if (paramLinks.SearchType === "google") {
        setLink(
          `https://www.google.fr/search?q=${paramLinks.SearchTerm}&gl=${paramLinks.Country}&hl=fr&gws_rd=cr&pws=0&uule=${paramLinks.Uule}`
        );
        setIsMaps(false);
      } else {
        setLink(
          `https://www.google.fr/search?q=${paramLinks.SearchTerm}&gl=${paramLinks.Country}&hl=fr&gws_rd=cr&pws=0&uule=${paramLinks.Uule}&tbm=lcl`
        );
        setIsMaps(true);
      }
      console.log(paramLinks);
    }
    generateLink();
  }, []);

  return (
    <div className="container-search-links">
      <div className="title-results">
        Voici vos résultats de recherche hyper localisé
      </div>
      <div className="subtitle-results">
        Cliquez sur la page de votre choix et découvrez le classement.
      </div>
      <div className="links">
        <img src={pagnination} className="pagination-google" />
        <div className="pagination-links">
          <a href={link} className="link-page" target="_blank">
            1
          </a>
          <a
            href={`${link}&start=${isMaps ? "20" : "10"}`}
            className="link-page"
            target="_blank"
          >
            2
          </a>
          <a
            href={`${link}&start=${isMaps ? "40" : "20"}`}
            className="link-page"
            target="_blank"
          >
            3
          </a>
          <a
            href={`${link}&start=${isMaps ? "60" : "30"}`}
            className="link-page"
            target="_blank"
          >
            4
          </a>
          <a
            href={`${link}&start=${isMaps ? "80" : "40"}`}
            className="link-page"
            target="_blank"
          >
            5
          </a>
          <a
            href={`${link}&start=${isMaps ? "100" : "50"}`}
            className="link-page"
            target="_blank"
          >
            6
          </a>
          <a
            href={`${link}&start=${isMaps ? "120" : "60"}`}
            className="link-page"
            target="_blank"
          >
            7
          </a>
          <a
            href={`${link}&start=${isMaps ? "140" : "70"}`}
            className="link-page"
            target="_blank"
          >
            8
          </a>
          <a
            href={`${link}&start=${isMaps ? "160" : "80"}`}
            className="link-page"
            target="_blank"
          >
            9
          </a>
          <a
            href={`${link}&start=${isMaps ? "180" : "90"}`}
            className="link-page"
            target="_blank"
          >
            10
          </a>
        </div>
      </div>
      <br />
      <div className="subtitle-results">Résumé de votre requête</div>
      <div className="search-summary">
        <table className="table-summary">
          <thead>
            <tr>
              <th>Requête</th>
              <th>Localisation</th>
              <th>Pays</th>
              <th>Moteur de recherche</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{paramLinks.SearchTerm}</td>
              <td>{paramLinks.Location}</td>
              <td>
                {paramLinks.Country === "fr"
                  ? "France 🇫🇷"
                  : paramLinks.Country === "be"
                  ? "Belgique 🇧🇪"
                  : paramLinks.Country === "ch"
                  ? "Suisse 🇨🇭"
                  : ""}
              </td>
              <td>
                {paramLinks.SearchType === "google"
                  ? "Recherche Google"
                  : "Google Maps"}
              </td>
            </tr>
          </tbody>
        </table>
        <ul className="summary-list">
          <li>
            Requête :{" "}
            <span className="li-summary">{paramLinks.SearchTerm}</span>
          </li>
          <li>
            Localisation :
            <span className="li-summary">{paramLinks.Location}</span>
          </li>
          <li>
            Pays :{" "}
            <span className="li-summary">
              {paramLinks.Country === "fr"
                ? "France 🇫🇷"
                : paramLinks.Country === "be"
                ? "Belgique 🇧🇪"
                : paramLinks.Country === "ch"
                ? "Suisse 🇨🇭"
                : ""}
            </span>
          </li>
          <li>
            Moteur de recherche :{" "}
            <span className="li-summary">
              {paramLinks.SearchType === "google"
                ? "Recherche Google"
                : "Google Maps"}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ResultLocalSearch;
