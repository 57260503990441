import React from "react";
import { Icon, Row, Col, Collapse } from "antd";
import "./GMBScoring.css";
import LockOverview from "./LockOverview";

const { Panel } = Collapse;

const ValidCriteria = ({ valid, hasFullAccess, handleNewUnlockStripe }) => {
  return (
    <div className="issues-container">
      <h3 style={{ marginTop: "30px" }}>
        <span role="icon" aria-label="emoji">
          ✅
        </span>{" "}
        Critères optimisés ({valid.length})
      </h3>
      <Row gutter={30}>
        <Col span={24}>
          <div className="issues-list">
            {valid.length === 0 && <span>Aucun problème détecté</span>}
            {valid.length > 0 && !hasFullAccess ? (
              <>
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1"]}
                  style={{ textAlign: "left", color: "#00000" }}
                >
                  {valid.slice(0, 3).map((val) => {
                    return (
                      <Panel
                        header={val.header}
                        extra={<Icon type="warning" />}
                        key={val.header}
                      >
                        <p style={{ paddingLeft: 24 }}>{val.content}</p>
                      </Panel>
                    );
                  })}
                </Collapse>
                <LockOverview
                  handleNewUnlockStripe={handleNewUnlockStripe}
                  isIssue={false}
                />
              </>
            ) : (
              ""
            )}
            {valid.length > 0 && hasFullAccess ? (
              <>
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1"]}
                  style={{ textAlign: "left", color: "#00000" }}
                >
                  {valid.map((val) => {
                    return (
                      <Panel
                        header={val.header}
                        extra={<Icon type="warning" />}
                        key={val.header}
                      >
                        <p style={{ paddingLeft: 24 }}>{val.content}</p>
                      </Panel>
                    );
                  })}
                </Collapse>
              </>
            ) : (
              ""
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ValidCriteria;
