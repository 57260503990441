import React, { useState, useEffect } from "react";
import { Bubble } from "react-chartjs-2";
import { Skeleton } from "antd";
import { getCompetitorsDataForBubble } from "../../utils/competitors";

const BubbleChartCompetitors = ({ competitors, companyInfo }) => {
  const [loading, setLoading] = useState(true);
  const [dataCompetitors, setData] = useState();

  useEffect(() => {
    const setGraph = async () => {
      const dataC = await getCompetitorsDataForBubble(
        competitors,
        10,
        companyInfo
      );
      let data = {
        datasets: dataC,
      };
      setData(data);
      setLoading(false);
    };
    setGraph();
  }, [companyInfo, competitors]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
      position: "bottom",
    },
    tooltips: {
      callbacks: {
        label: function (t, dataCompetitors) {
          return (
            dataCompetitors.datasets[t.datasetIndex].label +
            ": (Note : " +
            dataCompetitors.datasets[t.datasetIndex].data[0].x +
            ", Avis : " +
            dataCompetitors.datasets[t.datasetIndex].data[0].y +
            ")"
          );
        },
      },
    },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Note moyenne",
          },
          ticks: {
            fontColor: "#00000052",
            suggestedMin: 1,
            suggestedMax: 5,
          },
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Nombre d'avis",
          },
          ticks: {
            fontColor: "#00000052",
            // suggestedMin: 0,
            stepSize: `${
              companyInfo.nbReviews >= 500
                ? 200
                : companyInfo.nbReviews >= 100 && companyInfo.nbReviews < 500
                ? 100
                : companyInfo.nbReviews <= 50
                ? 5
                : 50
            }`,
          },
        },
      ],
    },
  };

  return (
    <>
      {!loading ? (
        <>
          <Bubble data={dataCompetitors} options={options} />
        </>
      ) : (
        <>
          <Skeleton active />
        </>
      )}
    </>
  );
};

export default BubbleChartCompetitors;
