import React, { Component } from "react";
import { Layout } from "antd";
import { Route, Switch, withRouter } from "react-router-dom";
import audit from "./Audit/Audit.jsx";
import locationChecker from "./SearchLocationChecker/SearchLocationCheck";
import home from "./Home/Home";
import HeaderApp from "../components/Header/Header";

const { Header, Content } = Layout;

class App extends Component {
  state = {
    loading: false,
  };

  render() {
    return (
      <div>
        <Layout className="App main">
          <Header
            style={{
              width: "100%",
              backgroundColor: "#FFF",
              borderBottom: "1px solid #80808017",
              height: "56px",
              position: "sticky",
              top: "0",
              zIndex: "20",
            }}
          >
            <HeaderApp />
          </Header>
          <Layout>
            <Content
              style={{
                backgroundColor: "#f4f7fd",
                height: "100%",
                minHeight: "100vh",
              }}
            >
              <Switch>
                <Route exact path="/" component={home} />
                <Route
                  exact
                  path="/audit-google-my-business"
                  component={audit}
                />
                <Route
                  exact
                  path="/serp-local-google"
                  component={locationChecker}
                />
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}

export default withRouter(App);
