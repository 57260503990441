import React, { useState, useEffect } from "react";
import { Link, withRouter, useLocation } from "react-router-dom";
import {
  Input,
  message,
  Form,
  Button,
  Row,
  Col,
  Icon,
  Radio,
  Select,
} from "antd";
import Recaptcha from "react-recaptcha";
import { hasErrors, verticalFormLayout } from "../../utils/forms";
import "./FormLocalSearch.css";
import IconMaps from "./assets/icon-maps.png";
import IconGoogle from "./assets/icon-google.png";
import ModalMoreInfoSerp from "../../App/SearchLocationChecker/ModalMoreInfoSerp";

const { Option, OptGroup } = Select;

export const LocalSearch = Form.create({ name: "LocalSearch" })(
  ({ form, onLocalSearchSubmit }) => {
    const { getFieldDecorator, getFieldsError, getFieldValue } = form;
    const [isLoading, setIsLoading] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [modalBusinessInfo, setModalBusinessInfo] = useState(false);
    const [searchObj, setSearchObj] = useState({});
    const [isSA, setIsSA] = useState(false);
    const location = useLocation();

    useEffect(() => {
      const isSuperAdmin = () => {
        if (location.search.includes("access=localranker")) {
          setIsSA(true);
        }
      };

      isSuperAdmin();
    }, []);

    const verifyCallback = (response) => {
      console.log(response);
      if (response) {
        setIsVerified(true);
      }
    };

    const onSubmit = async () => {
      try {
        if (isSA) {
          setIsLoading(true);
          const search = {
            searchTerm: getFieldValue("SearchTerm"),
            location: getFieldValue("Location"),
            country: getFieldValue("Country"),
            searchType: getFieldValue("SearchType"),
          };
          setSearchObj(search);
          await onLocalSearchSubmit(search);
        } else {
          if (isVerified) {
            setIsLoading(true);
            const search = {
              searchTerm: getFieldValue("SearchTerm"),
              location: getFieldValue("Location"),
              country: getFieldValue("Country"),
              searchType: getFieldValue("SearchType"),
            };
            setSearchObj(search);
            setModalBusinessInfo(true);
          } else {
            message.warning(
              "Veuillez remplir le captcha pour accéder aux résultats."
            );
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <div className="local-serp">
        <Form {...verticalFormLayout} className="align-left">
          <Row gutter={10}>
            <Col
              span={12}
              lg={12}
              md={24}
              xl={12}
              xs={24}
              sm={24}
              className="full"
            >
              <Form.Item className="no-margin" {...verticalFormLayout}>
                {getFieldDecorator("SearchTerm", {
                  rules: [
                    {
                      required: true,
                      message: "Insérez votre requête cible",
                    },
                  ],
                })(<Input autoFocus placeholder="Terme de recherche" />)}
              </Form.Item>
            </Col>
            <Col
              span={12}
              lg={12}
              md={24}
              xl={12}
              xs={24}
              sm={24}
              className="full"
            >
              <Form.Item className="no-margin" {...verticalFormLayout}>
                {getFieldDecorator("Location", {
                  rules: [
                    {
                      required: true,
                      message: "Insérez la localisation désirée",
                    },
                  ],
                })(<Input autoFocus placeholder="Localisation (ex: 75012)" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col
              span={12}
              lg={12}
              md={24}
              xl={12}
              xs={24}
              sm={24}
              className="full"
            >
              <Form.Item
                className="no-margin"
                label="Adresse Email"
                {...verticalFormLayout}
              >
                {getFieldDecorator("Country", {
                  initialValue: "fr",
                  rules: [
                    {
                      required: true,
                      message: "Veuillez sélectionner un pays",
                    },
                  ],
                })(
                  <Select

                  //   onChange={handleChange}
                  >
                    <OptGroup label="Europe">
                      <Option value="fr">France 🇫🇷</Option>
                      <Option value="be">Belgique 🇧🇪</Option>
                      <Option value="ch">Suisse 🇨🇭</Option>
                    </OptGroup>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col
              span={12}
              lg={12}
              md={24}
              xl={12}
              xs={24}
              sm={24}
              className="full"
            >
              <Form.Item className="no-margin" {...verticalFormLayout}>
                {getFieldDecorator("SearchType", {
                  initialValue: "google",
                  rules: [
                    {
                      required: true,
                      message: "Veuillez choisir un moteur de recherche",
                    },
                  ],
                })(
                  <Radio.Group size="large">
                    <Radio.Button value="google">
                      <img src={IconGoogle} alt="icon Google" width="15px" />
                      {"  "}Recherche Google
                    </Radio.Button>
                    <Radio.Button value="maps">
                      <img src={IconMaps} alt="icon maps" width="15px" />
                      {"  "}Google Maps
                    </Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className="no-margin" {...verticalFormLayout}>
            {!isSA ? (
              <Recaptcha
                sitekey="6LdVyOsUAAAAALCyJsUuFb0qXONAbkFzMrSZww2_"
                render="explicit"
                verifyCallback={verifyCallback}
                style={{ marginLeft: "0px" }}
              />
            ) : (
              ""
            )}
          </Form.Item>
          <Form.Item className="no-margin" {...verticalFormLayout}>
            <Button
              onClick={onSubmit}
              className="signup-button"
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={
                !getFieldValue("SearchTerm") ||
                !getFieldValue("Location") ||
                !getFieldValue("Country") ||
                !getFieldValue("SearchType") ||
                hasErrors(getFieldsError())
              }
            >
              Voir les resultats
              <Icon type="arrow-right" />
            </Button>
          </Form.Item>
        </Form>
        <ModalMoreInfoSerp
          modalBusinessInfo={modalBusinessInfo}
          setModalBusinessInfo={setModalBusinessInfo}
          onLocalSearchSubmit={onLocalSearchSubmit}
          location={searchObj}
        />
      </div>
    );
  }
);

export default withRouter(LocalSearch);
