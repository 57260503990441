import React from "react";
import { Row, Col } from "antd";

import claimedImage from "../../FormLocalSearch/assets/audit/info-covid.png";
import horaires from "../../FormLocalSearch/assets/horaires-spéciaux-covid-19-gmb.png";
import attributs from "../../FormLocalSearch/assets/attributs-gmb-sante.png";

const InfoCOVID = () => {
  return (
    <div className="featureblock-container">
      <div className="card-header">
        <span style={{ fontSize: "19px" }}>
          <span role="img" aria-label="emojo">
            💉
          </span>{" "}
          Gérer la crise du COVID-19 avec Google My Business
        </span>
      </div>
      <div className="card-graph-body">
        <div className="criteria-audit">
          <Row gutter={30}>
            <Col xl={13} lg={13} sm={24}>
              <div className="criteria-status">
                <span>
                  <span role="img" className="issue-icon" aria-label="emoji">
                    1.
                  </span>
                  Mettre un Google Post COVID-19 sur toutes vos fiches
                </span>
              </div>
              <div className="criteria-description">
                <span>
                  La première étape est de mettre un Google Post Covid-19 sur
                  vos fiches GMB afin de prévenir vos clients, de comment le
                  COVID, ou le confinement affecte votre établissement.
                  <br />
                  <br />
                  Cela est clé pour garder une transparence avec vos clients.
                  Ainsi ils n'hésiteront pas à vous contacter si besoin.
                  <br />
                  <br />
                  Pour ce faire, Rendez-vous dans Google My Business, dans la
                  section Posts puis "Info COVID-19". Suivez ensuite les étapes
                  indiquées.
                </span>
                <br />
                <br />
              </div>
            </Col>
            <Col xl={11} lg={11} sm={24}>
              <img
                src={claimedImage}
                width="100%"
                alt="info covid google posts"
              />
            </Col>
          </Row>
        </div>
        <div className="criteria-audit">
          <Row gutter={30}>
            <Col xl={13} lg={13} sm={24}>
              <div className="criteria-status">
                <span>
                  <span role="img" className="issue-icon" aria-label="emoji">
                    2.
                  </span>
                  Mettre des horaires spécifiques si besoin
                </span>
              </div>
              <div className="criteria-description">
                <span>
                  La deuxième étape pour assurer une bonne transition avec le
                  confinement est d'ajouter vos nouveaux horaires spécifiques
                  sur votre fiche GMB.
                  <br />
                  <br />
                  En effet, il est possible que vous ouvriez à différents
                  horaires qu'habituellement, il est donc bon de le notifier à
                  vos clients afin d'éviter toutes mésaventures.
                  <br />
                  <br />
                  Pour ce faire, rendez-vous directement sur Google My Business,
                  dans la section Info et ajoutez vos nouveaux horaires dans la
                  section "Ajouter des horaires spéciaux".
                </span>
                <br />
                <br />
              </div>
            </Col>
            <Col xl={11} lg={11} sm={24}>
              <img src={horaires} width="100%" alt="horaires spéciaux GMB" />
            </Col>
          </Row>
        </div>
        <div className="criteria-audit">
          <Row gutter={30}>
            <Col xl={14} lg={14} sm={24}>
              <div className="criteria-status">
                <span>
                  {" "}
                  <span
                    // role="img"
                    className="issue-icon"
                    // aria-label="emoji"
                  >
                    3.
                  </span>
                  Changer les attributs (santé et sécurité) si cela n’a pas
                  encore été déjà fait{" "}
                </span>
              </div>
              <div className="criteria-description">
                <span>
                  Le dernier point est de s'assurer que votre fiche Google
                  reflète la réalité en terme de protection sanitaire. C'est à
                  dire, vous allez indiquer à Google quelles mesures vous avez
                  mis en place dans votre business.
                </span>
                <br />
                <br />
                <span>
                  Pour ce faire, rien de plus simple. Rendez-vous encore une
                  fois dans Google My Business, section "Infos" et cliquez sur
                  la section "Points forts santé et sécurité". Suivez ensuite
                  les étapes simplement.
                </span>
              </div>
            </Col>
            <Col xl={10} lg={10} sm={24}>
              {" "}
              <img src={attributs} width="100%" alt="category" />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default InfoCOVID;
