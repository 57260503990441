import React, { useEffect, useState } from "react";
import { Row, Col, Skeleton } from "antd";

import claimedImage from "../../FormLocalSearch/assets/audit/revendication.png";
import category from "../../FormLocalSearch/assets/audit/categorie.png";
import ProgressCircle from "../ProgressCircle";

const Essentials = ({ auditInfo }) => {
  const [score, setScore] = useState();
  const [categories, setCategories] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getScore = async () => {
      let score = 0;
      if (auditInfo.IsCompanyClaimed) {
        score = score + 50;
      }
      if (auditInfo.Industry) {
        score = score + 50;
      }
      if (auditInfo.Categories) {
        setCategories(JSON.parse(auditInfo.Categories));
      } else {
        setCategories(null);
      }
      setScore(score);
      setLoading(false);
    };
    getScore();
  }, [auditInfo.IsCompanyClaimed, auditInfo.Industry, auditInfo.Categories]);

  if (loading) {
    return (
      <>
        <div className="featureblock-container">
          <div className="card-header">
            <span>Indispensables</span>
          </div>
          <div className="card-graph-body">
            <div className="sumup-category">
              <Skeleton active />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="featureblock-container">
          <div className="card-header">
            <span>Indispensables</span>
          </div>
          <div className="card-graph-body">
            <div className="sumup-category">
              <Row>
                <Col xl={10} lg={10} sm={24} className="progress-category">
                  <ProgressCircle type="circle" score={score} />
                </Col>
                <Col xl={14} lg={14} sm={24}>
                  <div style={{ marginTop: "20px" }}>
                    Les éléments ci-dessous sont les étapes les plus importantes
                    dans l’optimisation de votre fiche Google My Business. Ce
                    sont des facteurs à travailler en priorité pour pouvoir
                    optimiser le reste. Bien suivre les étapes pour avoir une
                    optimisation optimale de votre fiche GMB.
                  </div>
                </Col>
              </Row>
            </div>
            <div className="criteria-audit">
              <Row gutter={30}>
                <Col xl={13} lg={13} sm={24}>
                  <div className="criteria-status">
                    <span>
                      {auditInfo.IsCompanyClaimed ? (
                        <>
                          <span
                            role="img"
                            className="issue-icon"
                            aria-label="emoji"
                          >
                            ✅
                          </span>
                          Établissement revendiqué{" "}
                        </>
                      ) : (
                        <>
                          <span
                            role="img"
                            className="issue-icon"
                            aria-label="emoji"
                          >
                            ❌
                          </span>
                          Établissement non-revendiqué
                        </>
                      )}
                    </span>
                  </div>
                  <div className="criteria-description">
                    <span>
                      <b>Revendiquez votre fiche Google My Business</b> est la
                      première étape pour votre SEO local.
                      <br />
                      <br />
                      Si vous n’avez pas revendiqué votre GMB, vous ne pourrez
                      pas optimiser votre fiche. Lorsque vous revendiquez votre
                      fiche GMB, vous pouvez modifier vos informations.
                      <br />
                      <br />
                      Si votre fiche n’est pas créé vous devez suivre la
                      procédure pour valider votre établissement.
                    </span>
                    <br />
                    <br />
                  </div>
                </Col>
                <Col xl={11} lg={11} sm={24}>
                  <img
                    src={claimedImage}
                    width="100%"
                    alt="établissement revendiqué"
                  />
                </Col>
              </Row>
            </div>
            <div className="criteria-audit">
              <Row gutter={30}>
                <Col xl={14} lg={14} sm={24}>
                  <div className="criteria-status">
                    <span>
                      {auditInfo.Industry ? (
                        <>
                          {" "}
                          <span
                            role="img"
                            className="issue-icon"
                            aria-label="emoji"
                          >
                            ✅
                          </span>
                          Catégorie renseignée{" "}
                        </>
                      ) : (
                        <>
                          {" "}
                          <span
                            role="img"
                            className="issue-icon"
                            aria-label="emoji"
                          >
                            ❌
                          </span>
                          Catégorie non trouvée
                        </>
                      )}
                    </span>
                  </div>
                  <div className="criteria-description">
                    {auditInfo.Industry ? (
                      <>
                        <span style={{ fontSize: 16 }}>
                          <b>
                            Votre catégorie principale : {auditInfo.Industry}
                          </b>
                        </span>
                        <br />
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    <span>
                      Vous êtes obligé d’avoir une catégorie mais vous devez
                      avoir la bonne catégorie principale et les bonnes
                      catégories secondaires.
                    </span>
                    <br />
                    <br />
                    {categories ? (
                      <div className="category-list">
                        <span style={{ fontSize: 17 }}>
                          Catégories cohérentes avec votre établissement :
                          <br />
                          <br />
                          <ul>
                            {categories.map((category) => (
                              <li
                                key={category}
                                style={{
                                  listStyle: "none",
                                  paddingBottom: 10,
                                  color: "#3ecb3e",
                                }}
                              >
                                + {category}
                              </li>
                            ))}
                          </ul>
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                <Col xl={10} lg={10} sm={24}>
                  {" "}
                  <img src={category} width="100%" alt="category" />
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="featureblock-container-info">
          <div className="card-header">
            <span>Le saviez-vous ?</span>
          </div>
          <div className="card-graph-body">
            <div className="sumup-category">
              <Row>
                <Col xl={18} lg={18} sm={24}>
                  <div className="criteria-status">
                    <span>
                      <span
                        role="icon"
                        className="issue-icon"
                        aria-label="emoji"
                      >
                        💡
                      </span>
                      Ne pas sur-optimiser le nom de votre établissement
                    </span>
                  </div>
                  <div className="criteria-description">
                    <span>
                      Sur-optimiser son nom d’établissement est mal vu par
                      Google, essayez de ne pas mettre de mot-clé pour ne pas
                      avoir une fiche suspendue.
                    </span>
                    <span>
                      Lorsque vous mettez un mot clé dans votre nom d’enseigne
                      vous risquez de suspendre votre Google my Business et par
                      la même occasion empêcher votre fiche d’être référencée.
                      <br />
                      <br />
                      Contrairement à la pensée collective, mettre des mots-clés
                      vous fera plus de mal que de bien.
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Essentials;
