import React from "react";

import logo1 from "../../components/FormLocalSearch/assets/logo-airotel.png";
import logo2 from "../../components/FormLocalSearch/assets/logo-alain.png";
import logo3 from "../../components/FormLocalSearch/assets/logo-cafe-de-france.png";
import logo4 from "../../components/FormLocalSearch/assets/logo-231-east-burger.png";
import logo5 from "../../components/FormLocalSearch/assets/wazawok-logo.jpg";

const Logos = () => {
  return (
    <div className="container">
      <div className="logos">
        <div className="logo-client">
          <img src={logo1} alt="logo" width="90px" />
        </div>
        <div className="logo-client">
          <img src={logo2} alt="logo" width="90px" />
        </div>
        <div className="logo-client">
          <img src={logo3} alt="logo" width="110px" />
        </div>
        <div className="logo-client">
          <img src={logo4} alt="logo" width="90px" />
        </div>
        <div className="logo-client">
          <img src={logo5} alt="logo" width="90px" />
        </div>
      </div>
    </div>
  );
};

export default Logos;
