import React, { useState } from "react";

import {
  Row,
  Col,
  Icon,
  message,
  Progress,
  Collapse,
  Select,
  Avatar,
} from "antd";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import "react-google-places-autocomplete/dist/assets/index.css";

import "./SearchBar.css";

const SearchBar = ({
  submitButton,
  setBusinessAddress,
  businessAddress,
  handleOpenModalBusinessInfo,
  submitBusiness,
  isSa,
}) => {
  const onSubmit = async () => {
    if (businessAddress === undefined) {
      message.error("Please fill in the input first");
    } else {
      try {
        if (!isSa) {
          await handleOpenModalBusinessInfo();
        } else {
          await submitBusiness(businessAddress);
        }
      } catch (err) {
        console.log("error");
        message.error(err);
        // message.error(err)
      }
    }
  };

  return (
    <div>
      <div className="form form-audit">
        <Row className="input-row">
          <Col span={12} className="input-col">
            <GooglePlacesAutocomplete
              placeholder="Nom de votre établissement"
              onSelect={({ description }) => setBusinessAddress(description)}
              className="input-businessName"
              renderSuggestions={(active, suggestions, onSelectSuggestion) => (
                <div className="suggestions-container">
                  {suggestions.map((suggestion, index) => (
                    <div
                      className="suggestion"
                      key={index}
                      onClick={(event) => onSelectSuggestion(suggestion, event)}
                    >
                      <Icon type="environment" />
                      {suggestion.description}
                    </div>
                  ))}
                </div>
              )}
            />
          </Col>
          <Col span={12} className="input-col">
            <button className="submit-button" type="submit" onClick={onSubmit}>
              {submitButton}
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SearchBar;
