import React from "react";
import { Row, Col, Divider } from "antd";
import pictoGMB from "../../components/FormLocalSearch/assets/shop-mybusiness.svg";
import auditLogo from "../../components/FormLocalSearch/assets/audit-logo.svg";
import Logos from "./Logos";

const InfoStats = () => {
  return (
    <div className="info-stats">
      <Row>
        <Col
          xl={12}
          lg={12}
          md={24}
          sm={24}
          xs={24}
          style={{ marginTop: "20px" }}
        >
          <div className="stats-picto">
            <img src={auditLogo} alt="audits seo local picto" />
          </div>
          <div className="stats-figure">+ de 5000</div>
          <div className="stats-des">audits réalisés par semaine</div>
        </Col>
        <Col
          xl={12}
          lg={12}
          md={24}
          sm={24}
          xs={24}
          style={{ marginTop: "20px" }}
        >
          <div className="stats-picto">
            <img src={pictoGMB} alt="audits seo local picto" />
          </div>
          <div className="stats-figure">+ de 1500</div>
          <div className="stats-des">fiches GMB optimisées par mois</div>
        </Col>
      </Row>
      <Logos />
    </div>
  );
};

export default InfoStats;
