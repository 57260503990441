import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import "./GMBScoring.css";
import {
  Progress,
  Collapse,
  Avatar,
  Row,
  Col,
  Tabs,
  Button,
  Icon,
  Input,
  Carousel,
} from "antd";
import TopIssues from "./TopIssues";
import ValidCriteria from "./ValidCriteria";
import Essentials from "./FeaturesBlocks/Essentials";
import ProgressCircle from "./ProgressCircle";
import Informations from "./FeaturesBlocks/Informations";
import Medias from "./FeaturesBlocks/Medias";
import Reviews from "./FeaturesBlocks/Reviews";
import Engagement from "./FeaturesBlocks/Engagement";
import AuditCompetitors from "./FeaturesBlocks/AuditCompetitors";
import InfoCOVID from "./FeaturesBlocks/InfoCOVID";
import review1 from "../FormLocalSearch/assets/review-1.png";
import review2 from "../FormLocalSearch/assets/review-2.png";
import review3 from "../FormLocalSearch/assets/review-3.png";

const { Panel } = Collapse;
const { TabPane } = Tabs;

const GMBScoring = ({
  copied,
  setCopied,
  sessionID,
  googleInfo,
  displayGMBSection,
  hasFullAccess,
}) => {
  console.log(googleInfo);
  return (
    <div>
      {displayGMBSection && (
        <div className="container">
          <div className="businessInfo companyInfo">
            <Row>
              <Col span={24} className="company-description">
                <span style={{ color: "#3c3c3c", marginTop: "15px" }}>
                  <a
                    href={`https://local.google.com/place?id=${googleInfo.business.Ludocid}&use=srp&hl=fr`}
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{ marginRight: "5px" }}
                  >
                    <Icon style={{ color: "grey" }} type="link" />
                  </a>{" "}
                  {googleInfo.business.BusinessName}
                </span>{" "}
                | {googleInfo.business.Address}
                <br />
                <span style={{ textAlign: "center" }}>
                  {googleInfo.business.Industry && (
                    <div className="tag">{googleInfo.business.Industry}</div>
                  )}
                </span>
                <span
                  style={{
                    color: "#3c3c3c",
                    textAlign: "center",
                    fontSize: "14px",
                  }}
                >
                  {googleInfo.business.Phone} |{" "}
                  <a href={googleInfo.business.Website}>
                    {googleInfo.business.Website}
                  </a>
                </span>
                <br />
                <span
                  style={{
                    color: "#3c3c3c",
                    textAlign: "center",
                    fontSize: "14px",
                  }}
                >
                  {googleInfo.business.Nbreviews > 0 ? (
                    <>
                      Noté {googleInfo.business.Rating}{" "}
                      <span role="img" aria-label="emoji">
                        ⭐️
                      </span>{" "}
                      sur {googleInfo.business.Nbreviews} avis
                    </>
                  ) : (
                    "Vous n'avez aucun avis."
                  )}
                </span>
                <br />
                <span style={{ fontSize: "16px" }}>
                  🔥{" "}
                  <a
                    href={`http://search.google.com/local/writereview?placeid=${googleInfo.business.PlaceID}&hl=fr`}
                    target="_blank"
                  >
                    Votre lien direct pour laisser un avis.
                  </a>{" "}
                  (à partager un maximum)
                </span>
              </Col>
            </Row>
          </div>
          <div className="companyGMB companyInfo">
            <Tabs defaultActiveKey="0">
              <TabPane tab="Vue d'ensemble" key="0">
                <div className="block-audit">
                  <div className="cards">
                    <div className="card-stat">
                      <div className="card-title">Local Score</div>
                      <ProgressCircle
                        score={googleInfo.business.LocalScore}
                        type="circle"
                      />
                    </div>
                    <div className="card-stat">
                      <div className="card-title">Critères valides</div>
                      <div className="green criteria">
                        {googleInfo.valid.length}
                      </div>
                    </div>
                    <div className="card-stat">
                      <div className="card-title">Problèmes détectés</div>
                      <div className="red criteria">
                        {googleInfo.issues.length}
                      </div>
                    </div>
                    <div className="card-stat">
                      <div className="card-title">Concurrence</div>
                      <div
                        className={
                          googleInfo.business.Competition === "High"
                            ? "criteria red middle"
                            : googleInfo.business.Competition === "Low"
                            ? "criteria green middle"
                            : "criteria orange middle"
                        }
                      >
                        {googleInfo.business.Competition === "unknown"
                          ? "Moyenne"
                          : googleInfo.business.Competition === "High"
                          ? "Élevée"
                          : googleInfo.business.Competition === "Low"
                          ? "Faible"
                          : googleInfo.business.Competition === "Medium"
                          ? "Moyenne"
                          : "Moyenne"}
                      </div>
                    </div>
                  </div>

                  <h2 className="title-secondary">
                    <span role="img" aria-label="emoji">
                      🔎
                    </span>{" "}
                    Détail de l'audit de votre fiche GMB
                  </h2>
                  <TopIssues
                    hasFullAccess={hasFullAccess}
                    issues={googleInfo.issues}
                  />
                  <ValidCriteria
                    hasFullAccess={hasFullAccess}
                    valid={googleInfo.valid}
                  />
                </div>
              </TabPane>
              <TabPane tab="Info COVID-19" key="10">
                <div className="block-audit">
                  <InfoCOVID />
                </div>
              </TabPane>
              <TabPane tab="Indispensables" key="1">
                <div className="block-audit">
                  <Essentials auditInfo={googleInfo.business} />
                </div>
              </TabPane>
              <TabPane
                tab="Concurrents"
                key="7"
                disabled={hasFullAccess ? false : true}
              >
                <div className="block-audit">
                  <AuditCompetitors auditInfo={googleInfo.business} />
                </div>
              </TabPane>
              {/* <TabPane tab="Doublons" key="2">
              <Duplicate auditInfo={companyInfo.business} />
            </TabPane> */}
              <TabPane
                tab="Informations"
                key="3"
                disabled={hasFullAccess ? false : true}
              >
                <div className="block-audit">
                  <Informations auditInfo={googleInfo.business} />
                </div>
              </TabPane>
              <TabPane
                tab="Médias"
                key="4"
                disabled={hasFullAccess ? false : true}
              >
                <div className="block-audit">
                  <Medias auditInfo={googleInfo.business} />
                </div>
              </TabPane>
              <TabPane
                tab="Avis"
                key="5"
                disabled={hasFullAccess ? false : true}
              >
                <div className="block-audit">
                  <Reviews auditInfo={googleInfo.business} />
                </div>
              </TabPane>
              <TabPane
                tab="Engagement"
                key="6"
                disabled={hasFullAccess ? false : true}
              >
                <div className="block-audit">
                  <Engagement auditInfo={googleInfo.business} />
                </div>
              </TabPane>
            </Tabs>
          </div>
          <div className="container carousel-section">
            <div
              className="companyInfo"
              style={{ textAlign: "center", paddingTop: "20px" }}
            >
              <div className="title-card">
                <span>💡 Découvrez ce que nos clients en pensent</span>
              </div>
              <Carousel autoplay>
                <div className="carousel-img">
                  <img src={review1} alt="review 1" width="80%" />
                </div>
                <div className="carousel-img">
                  <img src={review2} alt="review 2" width="80%" />
                </div>
                <div className="carousel-img">
                  <img src={review3} alt="review 3" width="80%" />
                </div>
              </Carousel>
            </div>
          </div>
          {!hasFullAccess ? (
            <div className="container">
              <div
                className="companyInfo"
                style={{ textAlign: "center", paddingTop: "20px" }}
              >
                <div className="title-card">
                  <span>🔓 Débloquez l'audit complet de votre fiche GMB</span>
                </div>
                <div className="body-card">
                  <span>
                    Points bloquants sur votre fiche, lien pour avoir plus
                    d'avis, analyse concurrentielle locale, explications sur les
                    optimisations à réaliser.
                  </span>
                </div>
                <div className="cta-card">
                  <Button
                    onClick={() => {
                      window.open(
                        "https://localranker.fr/demander-demo/?utm_source=localranker.tech&utm_campaign=banner-bottom",
                        "_blank"
                      );
                    }}
                    style={{ width: "fit-content" }}
                    type="primary"
                    className="new-search-button"
                  >
                    Développer votre présence locale avec LocalRanker
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="container">
              <div
                className="companyInfo"
                style={{ textAlign: "center", paddingTop: "20px" }}
              >
                <div className="title-card">
                  <span>
                    ⭐️ Devenez le meilleur étabissement dans votre secteur
                  </span>
                </div>
                <div className="body-card">
                  <span>
                    Découvrez LocalRanker, la première plateforme de gestion et
                    d'optmisation Google My Business.
                  </span>
                </div>
                <div className="cta-card">
                  <a
                    href="https://localranker.fr/demander-demo/?utm_source=localranker.tech&utm_campaign=banner-bottom"
                    target="_blank"
                    className="new-search-button"
                  >
                    Découvrir LocalRanker
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GMBScoring;
