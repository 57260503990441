import React, { useState, useEffect } from "react";
import { Progress } from "antd";

const ProgressCircle = ({ score, width, type }) => {
  const [strokeC, setStrokeC] = useState("#F3F3F3");

  useEffect(() => {
    const getStrokeColor = () => {
      if (score < 30) {
        setStrokeC("#FF4E4E");
      } else if (score >= 30 && score <= 89) {
        setStrokeC("#FFA300");
      } else if (score > 89) {
        setStrokeC("#0ACE6A");
      }
    };
    getStrokeColor();
  }, [score]);

  return (
    <>
      <Progress
        type={type}
        percent={score}
        format={(percent) => `${score}`}
        width={width}
        strokeColor={strokeC}
        strokeWidth={7}
      />
    </>
  );
};

export default ProgressCircle;
